import WFacebookLikeComponent from '@wix/thunderbolt-elements/src/components/WFacebookLike/viewer/WFacebookLike';
import WFacebookLikeController from '@wix/thunderbolt-elements/src/components/WFacebookLike/viewer/WFacebookLike.controller';


const WFacebookLike = {
  component: WFacebookLikeComponent,
  controller: WFacebookLikeController
};


export const components = {
  ['WFacebookLike']: WFacebookLike
};

